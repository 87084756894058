
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import BCard from 'react-bootstrap/Card';
import axios from "../../utils/axios";
import { useEffect, useState } from "react";



import { useLocation } from "react-router-dom";
import { CiImageOff } from "react-icons/ci";
import SliderCarousel from "../slider/carousel";


function GenProductData(props) {
  const [business, setBusiness] = useState('NA');
  const { state } = useLocation();

  useEffect(() => {
    const id = state.genProductBusinessId;
    console.log(id);

    const fetchData = () => {
      axios.get(`business/GetBusinessById/` + id).then((data) => {
        console.log(data)
        setBusiness(data.data.Data.Name)
      })
    }
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
        <BCard>
          <BCard.Body>
            <Row>
              <Col md="4" className="d-flex align-items-center">
                <div className="text-center">
                  {state.genProductImage.length > 0 ?
                    <SliderCarousel images={state.genProductImage} />
                    :
                    <CiImageOff style={{
                      fontSize: '10rem',
                      marginTop: "20px",
                      marginLeft: "60px"
                    }} />
                  }
                </div>
              </Col>
              <Col md="8">
                <h6>Item Name:</h6><b style={{ fontSize: '1.5rem' }}>{state.genProductName}</b>
                <hr />
                <Row>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Item ID:</b><br></br> {state._id}
                      </p>
                    </Col>
                    {/* <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Property ID:</b><br></br> {state.propertyId}
                      </p>
                    </Col> */}
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Business Name:</b> <br></br>{business}
                      </p>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Installation Date:</b> <br></br>{state.genProductInstallationDate}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Last Service Date:</b> <br></br>{state.genProductLastServiceDate}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Next Service Date:</b> <br></br>{state.genProductNextServiceDate}
                      </p>
                    </Col>
                  </Row>
                  <Col md='12'><hr /></Col>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Manufacturer:</b> <br></br>{state.genProductNo}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Serial No. or Product ID:</b> <br></br>{state.genProductSerialNo}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Description:</b> <br></br>{state.genProductNotes}
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </BCard.Body>
        </BCard>
      </Container>
      <Footer />
    </DashboardLayout>
  );
}

export default GenProductData;