import Carousel from 'react-bootstrap/Carousel';
import imageUrl from 'utils/imageUrl';
import axios from "../../utils/axios";

function SliderCarousel({ images }) {
  return (
    <Carousel>
      {
        images.map((imageData) => {
          return (
            <Carousel.Item>
              <img className="w-100"
                src={`${imageUrl}${imageData}`}
                // alt="First slide"
                style={{
                  height: '200px',
                  width: '100%',
                  objectFit: 'cover'
                }}
              />
            </Carousel.Item>
          )
        })
      }
    </Carousel>
  );
}

export default SliderCarousel;