
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import BCard from 'react-bootstrap/Card';
import axios from "../../utils/axios";
import SliderCarousel from "../slider/carousel";
import { useEffect, useState } from "react";
// import { FaTachometerAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { CiImageOff } from "react-icons/ci";

function ProductData(props) {
  const [business, setBusiness] = useState('NA');
  const { state } = useLocation();

  useEffect(() => {
    const fetchData = () => {
      const id = state.productBusinessId;
      console.log(id);
      axios.get(`business/GetBusinessById/` + id).then((data) => {
        console.log(data);
        // console.log(data.data.Data.Name);
        setBusiness(data.data.Data.Name);
      })
    }
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
        <BCard>
          <BCard.Body>
            <Row>
              <Col md="4">
                <div className="text-center">
                  {state.productImage.length > 0 ?
                    <SliderCarousel images={state.productImage} />
                    :
                    <CiImageOff style={{
                      fontSize: '10rem',
                      marginTop: "70px",
                    }} />
                  }
                </div>
              </Col>
              <Col md="8">
                <h6>Product Name:</h6><b style={{ fontSize: '1.5rem' }}>{state.productName}</b>
                <hr />
                <Row>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Product ID:</b><br></br> {state._id}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Business Name:</b> <br></br>{business}
                      </p>
                    </Col>
                    {/* <Col md="6">
                      <p className="p" style={{fontSize:'1rem'}}>
                        <b>Room Name:</b><br></br> {state.roomId}
                      </p>
                    </Col> */}
                  </Row>
                  {/* <hr/> */}
                  <Col md='12'><hr /></Col>
                  <Row>
                    {/* <Col md="6">
                      <p className="p" style={{fontSize:'1rem'}}>
                        <b>Business ID:</b> <br></br>{state.productBusinessId}
                      </p>
                    </Col> */}
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Installation Date:</b> <br></br>{state.productInstallationDate}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Last Service Date:</b> <br></br>{state.productLastServiceDate}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md="6">
                      <p className="p" style={{fontSize:'1rem'}}>
                        <b>Last Service Date:</b> <br></br>{state.productLastServiceDate}
                      </p>
                    </Col> */}
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Next Service Date:</b> <br></br> <p style={{ color: 'green' }}>{state.productNextServiceDate}</p>
                      </p>
                    </Col>
                  </Row>
                  <Col md='12'><hr /></Col>
                  {/* <hr/> */}
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Product No:</b> <br></br>{state.productNo}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Product Serial No:</b> <br></br>{state.productSerialNo}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Product Notes:</b> <br></br>{state.productNotes}
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </BCard.Body>
        </BCard>
      </Container>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductData;