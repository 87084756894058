import React, {useState} from "react";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DataTable from "../../examples/Tables/DataTable";
import Spinner from "react-bootstrap/Spinner";
import credentialRequestsTableData from "./data/credentialRequestData";
import {ToastContainer} from "react-toastify";


function CredentialRequestsTable() {
    const [loader, setLoader] = useState(true);
    const handleLoader = (val) => {
        setLoader(val);
    };

    const {columns, rows} = credentialRequestsTableData(handleLoader);


    return (
        <DashboardLayout>
            <ToastContainer/>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <MDTypography variant="h6" color="white">
                                        Credential Requests
                                    </MDTypography>
                                </div>
                            </MDBox>
                            <MDBox pt={3}>
                                {rows.length > 0 ? (
                                    <DataTable
                                        table={{columns, rows}}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                ) : (
                                    <>
                                        <DataTable
                                            table={{columns, rows}}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                        {loader ? (
                                            <div className="w-100 text-center mb-4">
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        ) : (
                                            <div className="text-center mb-4">Requests not found!</div>
                                        )}
                                    </>
                                )}
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default CredentialRequestsTable;
