/** 
  All the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the React router.
  9. The `href` key is used to store the external link's location.
  10. The `title` key is only for the item with the type of `title` and it's used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
import PropertiesTables from "layouts/properties";
import UserTables from "layouts/users";
import BusinessTables from "layouts/businessDirectory";
import ProtectedRoute from "layouts/authentication/ProtectedRoute";
import Logout from "layouts/authentication/Logout";
import GuestRoute from "layouts/authentication/GuestRoute";
import AdvertisementTable from "layouts/Advertisement/index";
import PropertyData from "layouts/property/index";
import FloorData from "layouts/floor/index";
import RoomData from "layouts/room/index";
import ProductData from "layouts/product/index";
import GenProductData from "layouts/genproduct/index";
import ServiceData from "layouts/service/index";
import HistoryTable from "./layouts/history";
import BusinessData from "./layouts/businessDetail";
import CredentialRequestTable from './layouts/credentialRequests'
import IndexPage from "components/HomePage";
import HomePage from "components/HomePage";


// @mui icons
import Icon from "@mui/material/Icon";

import {
  FaTachometerAlt,
  FaUsers,
  FaUsersCog,
  FaRegBuilding,
  FaAdversal,
  FaSignOutAlt,
  FaHistory,
  FaKey,
} from "react-icons/fa";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <FaTachometerAlt />,
    route: "/dashboard",
    component: <ProtectedRoute cmp={<Dashboard />} />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "user_tables",
    icon: <FaUsers />,
    route: "/users",
    component: <ProtectedRoute cmp={<UserTables />} />,
  },
  {
    type: "collapse",
    name: "Business Directory",
    key: "business_tables",
    icon: <FaUsersCog />,
    route: "/business",
    component: <ProtectedRoute cmp={<BusinessTables />} />,
  },
  {
    type: "collapse",
    name: "Properties",
    key: "Property",
    icon: <FaRegBuilding />,
    route: "/properties",
    component: <ProtectedRoute cmp={<PropertiesTables />} />,
  },
  {
    type: "collapse",
    name: "Advertisement",
    key: "Ad",
    icon: <FaAdversal />,
    route: "/advertisement",
    component: <ProtectedRoute cmp={<AdvertisementTable />} />,
  },
  {
    type: "collapses",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    component: <GuestRoute cmp={<SignIn />} />,
  },
  {
    type: "collapses",
    name: "Property",
    key: "property",
    route: "/property",
    component: <ProtectedRoute cmp={<PropertyData />} />,
  },
  {
    type: "collapses",
    name: "Business Detail",
    key: "businessDetail",
    route: "/businessDetail/:id",
    component: <ProtectedRoute cmp={<BusinessData />} />,
  },
  {
    type: "collapses",
    name: "Floor",
    key: "floor",
    route: "/property/floor",
    component: <ProtectedRoute cmp={<FloorData />} />,
  },
  {
    type: "collapses",
    name: "Room",
    key: "room",
    route: "/property/floor/room",
    component: <ProtectedRoute cmp={<RoomData />} />,
  },
  {
    type: "collapses",
    name: "Product",
    key: "product",
    route: "/property/floor/room/product",
    component: <ProtectedRoute cmp={<ProductData />} />,
  },
  {
    type: "collapses",
    name: "item",
    key: "item",
    route: "/property/item",
    component: <ProtectedRoute cmp={<GenProductData />} />,
  },
  {
    type: "collapses",
    name: "service",
    key: "service",
    route: "/property/service",
    component: <ProtectedRoute cmp={<ServiceData />} />,
  },
  {
    type: "collapses",
    name: "service",
    key: "service",
    route: "/property/floor/room/service",
    component: <ProtectedRoute cmp={<ServiceData />} />,
  },
  {
    type: "collapse",
    name: "History",
    key: "history",
    icon: <FaHistory />,
    route: "/history",
    component: <ProtectedRoute cmp={<HistoryTable />} />,
  },
  {
    type: "collapse",
    name: "Credential Requests",
    key: "credential-request",
    icon: <FaKey />,
    route: "/credential-Requests",
    component: <ProtectedRoute cmp={<CredentialRequestTable />} />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "log-out",
    icon: <FaSignOutAlt />,
    route: "/logout",
    component: <ProtectedRoute cmp={<Logout />} />,
  },
];

export default routes;
