

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import AdminLogin from "../../modals/AdminLoginModal"

import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";
import UserData from "./userData";

export default function data(data) {
  var property = [];
  if (data !== null) {
    const dataTemp = data.map((property) => ({
      name: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="dark"
          fontWeight="medium"
        >
          {property.propertyName}
        </MDTypography>
      ),
      useremail: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="dark"
          fontWeight="medium"
        >
          {/* {property.userId} */}
          <UserData user={property.user} />
        </MDTypography>
      ),
      Country: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="dark"
          fontWeight="medium"
        >
          {property.propertyCountry}
        </MDTypography>
      ),
      State: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="dark"
          fontWeight="medium"
        >
          {property.propertyState}
        </MDTypography>
      ),
      City: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="dark"
          fontWeight="medium"
        >
          {property.propertyCity}
        </MDTypography>
      ),
      ZipCode: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="dark"
          fontWeight="medium"
        >
          {property.propertyZipCode}
        </MDTypography>
      ),
      Street: (
        <MDTypography
          component="a"
          href="#"
          variant="caption"
          color="dark"
          fontWeight="medium"
        >
          {property.propertyStreet}
        </MDTypography>
      ),
      // propertyId: (
      //   <MDTypography
      //     component="a"
      //     href="#"
      //     variant="caption"
      //     color="dark"
      //     fontWeight="medium"
      //   >
      //     {property._id}
      //   </MDTypography>
      // ),
      status: (
        <MDBox ml={-1}>
          {/* <MDButton onClick={()=>{  
            navigate('/property',{
              state: property
            });
          }}>View</MDButton> */}
          <AdminLogin property={property} />
        </MDBox>
      ),
    }));
    property = dataTemp;
  }

  return {
    tempColumns: [
      { Header: "name", accessor: "name", width: "20%", align: "left" },
      { Header: "user Email", accessor: "useremail", width: "20%", align: "center" },
      { Header: "Country", accessor: "Country", width: "10%", align: "center" },
      { Header: "State", accessor: "State", width: "20%", align: "center" },
      { Header: "City", accessor: "City", width: "20%", align: "center" },
      { Header: "ZIPCODE", accessor: "ZipCode", width: "10%", align: "center" },
      { Header: "Street", accessor: "Street", width: "20%", align: "center" },
      // { Header: "property Id", accessor: "propertyId", align: "center" },
      { Header: "Options", accessor: "status", align: "center" },
    ],
    tempRows: property,
  };
}