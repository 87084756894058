import React, { useState } from  'react' 
import Select from  'react-select' ;

function Categories({handler}) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    var options = [
        'Automotive',
        'Auto Accessories',
        'Auto Dealers – New',
        'Auto Dealers – Used',
        'Auto Detailing & Carwash',
        'Auto Body Shop & Paint',
        'Petrol & Gas Station',
        'Electric Vehicle Charging Station',
        'Hydrogen & Alternative Fuel Station',
        'Motorcycle Sales & Repair',
        'Car Rental & Leasing',
        'Auto Service, Repair & Parts',
        'Towing',
        'Tire Shop',
        'Parking',
        'Business Support & Supplies',
        'Consultants',
        'Employment Agency',
        'Marketing & Communications',
        'Office Supplies',
        'Printing & Publishing',
        'Computers & Electronics',
        'Computer Programming & Support',
        'Consumer Electronics & Accessories',
        'Networking Specialist',
        'Home Services',
        'Antiques & Collectibles',
        'Contractors',
        'Cleaning',
        'Crafts, Hobbies & Sports',
        'Electricians',
        'Home Furnishings',
        'Handyman',
        'Home Goods',
        'Home Improvements & Repairs',
        'Heating & Air Conditioning/HVAC',
        'Landscape & Lawn Service',
        'Keys & Locksmiths',
        'Pest Control',
        'Plumbers',
        'Pressure Washers',
        'Pool Supplies & Service',
        'Roofing',
        'Internet Service Providers',
        'Television Service Providers',
        'House Sitters',
        'Security System & Services',
        'Construction & Contractors',
        'Architects,Landscape Architects, Engineers & Surveyors',
        'Home Inspectors',
        'Home Automation',
        'Chimney Sweeps',
        'Excavation Services',
        'Fences & Gates',
        'Blasting & Demolition',
        'Building Materials & Supplies',
        'Construction Companies',
        'Furniture Assembly',
        'Garage Door Services',
        'Cabinetry',
        'Drywall Installation & Repair',
        'Windows Installation',
        'Electricians',
        'Engineer, Survey',
        'Environmental Assessments Inspectors',
        'Plaster & Concrete',
        'Painters',
        'Plumbers',
        'Roof Inspectors & Installers',
        'Renovators',
        'Patio Coverings',
        'Solar Installation & Solar Panel Cleaning',
        'Education',
        'Adult & Continuing Education',
        'Childcare',
        'Colleges & Universities',
        'Child Care & Day Care',
        'Early Childhood Education',
        'Educational Resources',
        'Language Schools',
        'Middle Schools & High Schools',
        'Private Schools',
        'Vocational & Technical School',
        'Other Educational',
        'Entertainment',
        'Artists, Writers',
        'Event Planners & Supplies',
        'Golf Courses',
        'Bowling Alley',
        'Gaming Zone',
        'Sporting Club',
        'Fitness Club & gym',
        'Boxing Club',
        'Cinema & Movies Productions',
        'Food & Dining',
        'Desserts, Catering & Supplies',
        'Fast Food & Carry Out',
        'Grocery',
        'Beverage & Tobacco',
        'Cafe & Club',
        'Pub',
        'Bakery',
        'Restaurants',
        'Health & Medicine',
        'Acupuncture',
        'Assisted Living & Home Health Care',
        'Rehab Center & Mental Health',
        'Audiologist',
        'Chiropractic Clinics & Medical Centers',
        'Dental',
        'Diet & Nutrition',
        'General Physician',
        'Laboratory, Imaging & Diagnostic',
        'Medical Supply Store',
        'Massage Therapy',
        'Nurse',
        'Optical',
        'Pharmacy, Drug & Vitamin Stores',
        'Physical Therapy',
        'Physicians & Assistants',
        'Podiatry',
        'Social Worker',
        'Animal Hospital',
        'Veterinary & Animal Surgeons',
        'IT Services',
        'Information technology company',
        'Digital Marketing',
        'Website Design & Development',
        'Social Media Management',
        'Web Hosting & Server Specialists',
        'IT Management & Service',
        'IT Outsourcing Service',
        'Cyber Security Consultants',
        'Software Development',
        'Mobile Application Development',
        'Computer security company',
        'Legal & Financial',
        'Accountants',
        'Attorneys',
        'Banks & Credit Unions',
        'Currency Exchange',
        'Debt Relief Services',
        'Financial Advising',
        'Financial Institutions',
        'Financial Services',
        'Insurance',
        'Mortgage Lenders',
        'Tax Preparation & Service',
        'Other Legal',
        'Manufacturing, Wholesale, Distribution',
        'Distribution, Import/Export',
        'Manufacturing',
        'Wholesale',
        'Merchants (Retail)',
        'Cards & Gifts',
        'Mobile Phone Shop',
        'Electronics retail company',
        'Flower Shop',
        'Concept store',
        'Clothing & Accessories',
        'Department Stores,',
        'Sporting Goods',
        'General Stores',
        'Jewelry',
        'Shoes',
        'Fashion Store',
        'E-Commerce and Online Store',
        'Miscellaneous',
        'Civic Groups',
        'Funeral Service Providers & Cemetaries',
        'Utility Companies',
        'Miscellaneous',
        'Personal Care & Services',
        'Animal Care & Supplies',
        'Barber & Beauty Salons',
        'Beauty Supplies',
        'Dry Cleaners & Laundromats',
        'Exercise & Fitness',
        'Massage & Body Works',
        'Nail Salons',
        'Shoemaker & Shoe Repairs',
        'Tailors',
        'Real Estate',
        'Agencies & Brokerage',
        'Agents & Brokers',
        'Realtors',
        'Apartment & Home Rental',
        'Mortgage Broker & Lender',
        'Property Management',
        'Title Company',
        'Travel & Transportation',
        'Hotel, Motel & Extended Stay',
        'Moving & Storage',
        'Limo Hire & Airport transfers',
        'Packaging & Shipping',
        'Transportation',
        'Taxi and Ride Hailing',
        'Ridesharing Company',
        'Travel & Tourism'
    ];

    options = options.map((option) => {
        return {
            label: option,
            value: option
        }
    });
    const onChange = (val) => {
        setSelectedOptions(val);
        var temp = val.map((option) => {
            return option.value;
        });
        // console.log(temp);
        handler(temp);
    }
    return (
        <Select
            className="basic-multi-select"
            classNamePrefix="select"
            isMulti
            value={selectedOptions}
            isOptionDisabled={() => selectedOptions.length >= 3}
            options={options}
            onChange={onChange}
            />
        
    )
}

export default Categories
