

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";

export default function data(floors) {
  const [floorsUIElements, setFloorsUIElements] = useState([]);
  const navigate = useNavigate();

  // console.log("Floors props:",floors);
  useEffect(() => {
    const FetchData = () => {
      if (floors !== undefined && floors.length > 0) {
        const dataTemp = floors.map((floor) => ({
          name: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {floor.floorName}
            </MDTypography>
          ),
          floorId: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {floor._id}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDButton onClick={() => {
                navigate('/property/floor', {
                  state: floor
                });
              }}>View</MDButton>
            </MDBox>
          ),
        }));
        setFloorsUIElements(dataTemp);
      }
      else {
        console.log('no floor')
      }
    };
    FetchData();
  }, []);

  return {
    columns: [
      { Header: "floor name", accessor: "name", width: "15%", align: "left" },
      { Header: "floor Id", accessor: "floorId", width: "65%", align: "center" },
      { Header: "status", accessor: "status", width: "20%", align: "center" },
    ],
    rows: floorsUIElements,
  };
}