import AdvertisementModal from 'layouts/modals/AdvertisementModal';
import React, {  useState,useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { CiImageOff } from 'react-icons/ci';
import axios from '../../../utils/axios';
import imageUrl from 'utils/imageUrl';
import UpdateAdvertisementModal from "layouts/modals/UpdateAdvertisementModal";

function Ad({ advertisementCode }) {
    const [advertisement, setAdvertisement] = useState(null);
    const [adId, setAdId] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const getAllAd = async () => {
            await axios.get('ad/getAllAd').then((data) => {
                if (data.data.Data !== undefined) {
                    const dataTemp = data.data.Data.map((item) => {
                        if (item.SponsorCode == advertisementCode) {
                            setAdvertisement(item.Image)
                            setAdId(item._id);
                        }
                    });
                }
                setIsLoaded(!isLoaded);
            }).catch((error) => console.log(error));
    }

    useEffect(() => {
        getAllAd();
    }, []);

    return (
        <>
            {
                isLoaded && <> <Row>
                    <p className="p" style={{ fontSize: '1.4rem' }}>
                        <b><u>Your Advertisement</u></b>
                    </p>
                </Row>
                    <Row>
                        <Col md='8'>
                            <div >
                                {(advertisement !== "" && advertisement !== null && advertisement !== undefined) ?
                                    <img src={`${imageUrl}${advertisement}`} className="w-300" style={{
                                        height: '120px',
                                        objectFit: 'cover'
                                    }} />
                                    :
                                    <>
                                        <CiImageOff style={{
                                            fontSize: '10rem',
                                            marginTop: "10px",
                                            marginBottom: "20px",
                                            marginLeft: "20px"
                                        }} />
                                        <p style={{ color: 'red' }}>You have not added any Advertisement.</p>
                                    </>
                                }
                            </div>
                        </Col>
                        <Col md='4'>
                            {
                                adId !== '' && adId !== null && adId !== undefined ?
                                    <UpdateAdvertisementModal adId={adId} />
                                    :
                                    <AdvertisementModal sponsorCode={advertisementCode} />
                            }
                        </Col>
                    </Row></>
            }
        </>
    )
}

export default Ad
