
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

// Images
// import team2 from "assets/images/team-2.jpg";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import imageUrl from "utils/imageUrl";

export default function data(handleLoader) {
  const [users, setUsers] = useState([]);
  const [status, setStatus] = useState(false);

  const handleSubmit = (event, id, active) => {
    event.preventDefault();
    if (active === true) {
      const json = {
        "Status": 1
      }
      axios.put(
        `user/statusUpdate/${id}`, json
      ).then((statusUpdate) => {
        console.log(statusUpdate)
        setStatus(!status);
      })
      // console.log('active')
    } else {
      const json = {
        "Status": 0
      }
      axios.put(
        `user/statusUpdate/${id}`, json
      ).then((statusUpdate) => {
        setStatus(!status);
        // console.log(statusUpdate)
      })
      console.log('inactive')
    }
  };
  useEffect(() => {
    const FetchData = () => {
      axios.get("user/getAllUser").then((dataU) => {
        if (dataU.data.Data !== null) {
          const dataTemp = dataU.data.Data.map((user) => ({
            name: <Name image={`${imageUrl}${user.Image}`} name={user.Name} />,
            status: (
              <div class="dropdown">
                <MDButton class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" color="info">Change Status</MDButton>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" onClick={event => handleSubmit(event, user._id, true)}>Active</a></li>
                  <li><a class="dropdown-item" onClick={event => handleSubmit(event, user._id, false)}>Inactive</a></li>
                </ul>
              </div>
            ),
            email: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {user.Email}
              </MDTypography>
            ),
            phone: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {user.Phone}
              </MDTypography>
            ),
            userId: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {user._id}
              </MDTypography>
            ),
            userStatus: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color={user.Status == 1 ? "success" : 'primary'}
                fontWeight="medium"
              >
                {user.Status == 1 ? 'ACTIVE' : 'INACTIVE'}
              </MDTypography>
            ),
          }));
          setUsers(dataTemp);
        }
        handleLoader(false);
      });
    };
    FetchData();
  }, [status]);

  const Name = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "name", accessor: "name", width: "20%", align: "left" },
      { Header: "email", accessor: "email", width: "15%", align: "center" },
      { Header: "phone", accessor: "phone", width: "5%", align: "center" },
      { Header: "user Id", accessor: "userId", align: "center" },
      { Header: "user Status", accessor: "userStatus", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
    ],
    rows: users,
  };
}