
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import productsTableData from "layouts/room/data/productsTableData";
import roomServiceTableData from "layouts/room/data/roomServiceTableData"

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import BCard from 'react-bootstrap/Card';
import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CiImageOff } from "react-icons/ci";
import imageUrl from "utils/imageUrl";

function RoomData(props) {
  const { state } = useLocation();
  const [products, setProducts] = useState(state.products);
  const { columns, rows } = productsTableData(products);
  const [services, setServices] = useState(state.services);
  // console.log('🔴', state.services);
  const { serviceColumns, serviceRows } = roomServiceTableData(services);
  // console.log('🔴', serviceColumns, serviceRows)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
        <BCard>
          <BCard.Body>
            <Row>
              <Col md="4">
                <div className="text-center">
                  {(state.roomImage !== "") ?
                    <img src={`${imageUrl}${state.roomImage}`} className="w-100" style={{
                      height: '300px',
                      objectFit: 'cover'
                    }} />
                    :
                    <CiImageOff style={{
                      fontSize: '10rem',
                      marginTop: "30px",
                      marginLeft: "10px"
                    }} />
                  }
                </div>
              </Col>
              <Col md="8">
                <h6>Room Name:</h6><b style={{ fontSize: '1.5rem' }}>{state.roomName}</b>
                <hr />
                <Row>
                  <Row>
                    {/* <Col md="6">
                      <p className="p" style={{fontSize:'1rem'}}>
                        <b>Room ID:</b><br></br> {state._id}
                      </p>
                    </Col> */}
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Floor ID:</b><br></br> {state.floorId}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Room Type:</b> <br></br>{state.roomType}
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </BCard.Body>
        </BCard>
      </Container>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Products
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  rows.length > 0 ?
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    :
                    <>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                      <div className="text-center mb-4">
                        No products added!
                      </div>
                    </>
                }
                {/* <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  /> */}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Services
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  serviceRows.length > 0 ?
                    <DataTable
                      table={{ columns: serviceColumns, rows: serviceRows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    :
                    <>
                      <DataTable
                        table={{ columns: serviceColumns, rows: serviceRows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                      <div className="text-center mb-4">
                        No Services added!
                      </div>
                    </>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default RoomData;