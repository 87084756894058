import React, { useState } from 'react'

function SponsorCheckbox({ handleCheckbox, id, checked }) {
    const [isChecked, setIsChecked] = useState(checked == "0" ? false : true);
    const handler = (e) => {
        var sponsor = -1;
        if (e.target.checked) {
            setIsChecked(true);
            sponsor = 1;
        } else {
            setIsChecked(false);
            sponsor = 0;
        }
        handleCheckbox(id, sponsor);
    }
    return (
        <>
            {
                isChecked ?
                    <div className="radio">
                        <input type="checkbox" checked onChange={handler} />
                    </div>
                    :
                    <div className="radio">
                        <input type="checkbox" onChange={handler} />
                    </div>
            }
        </>
    )
}

export default SponsorCheckbox
