import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import axios from "../../utils/axios";
import { toast, ToastContainer } from 'react-toastify';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Compressor from 'compressorjs';
import Spinner from 'react-bootstrap/Spinner';

function AdvertisementUpdateModal({adId}) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setImage('');
    setFile(null);
  }
  const handleShow = () => setShow(true);
  const [image, setImage] = useState('');
  const [file, setFile] = useState(null);
  const [disable, setDisable] = useState(false)

  const onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      var imageURL = URL.createObjectURL(img);
      setImage(imageURL);
      new Compressor(img, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          setFile(compressedResult);
          // console.log('img compressesd', compressedResult.size/1024/1024)
        }
    });
      // setFile(img);
    }
  };
  // alert(adId)
  const handleSubmit = () => {
    setDisable(true);
    if (file != null && image != '') {
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("SponsorCode", 'NA');
      axios.put(
        "ad/adUpdate/"+ adId, formData, {
        headers: {
          // Multer only parses "multipart/form-data" requests
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          setTimeout(() => {
            toast("Ad Updated sucessfully");
            setDisable(false);
            handleClose();
            location.reload();
          }, 1000);
        })
        .catch((e) => {
          setDisable(false);
          toast("Ad Could not be updated!");
        });
    } else {
      setDisable(false);
      toast("Please select an image first!");
    }
  };
  return (
    <>
      <ToastContainer />
      <div style={{
        // display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <MDTypography variant="h6" color="white">
          Advertisement
        </MDTypography>
        <MDButton color="info" width="100%" onClick={handleShow}>Update Advertisement</MDButton>
      </div>
      <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: '1200' }}>
        <Modal.Header closeButton>
          <Modal.Title>Update Advertisement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Choose Image</Form.Label>
                  <Form.Control type="file" accept='image/png, image/jpeg' placeholder="Add Ad Image" onChange={onImageChange} />
                  <Form.Text style={{ color: 'red' }}>
                    *Image should be Landscape Size 120*300.
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                {
                  image ?
                    <div style={{ textAlign: 'right' }}>
                      <img src={image} width="200px" />
                    </div>
                    :
                    ''
                }
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
        {disable ? <div className="w-100 text-center mb-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : <>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={disable ? true : false}>
            Update Ad
          </Button>
          </>}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdvertisementUpdateModal;