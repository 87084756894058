import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
import businessTableData from "layouts/businessDirectory/data/authorsTableData";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer } from "react-toastify";
import BusinessRegister from "../modals/businessRegisterModal";
import MDTypography from "components/MDTypography";
import axios from '../../utils/axios'

function BusinessTables() {

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  // const [categories, setCategories] = useState([]);


  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      axios.get('business/GetAllBusiness').then((dataB) => {
        const tempData = dataB.data.Data;
        setData(tempData);
        setFilteredData(tempData);
      }).finally((data) => {
        setLoader(false);
      });

    }
    fetchData();
  }, []);
  
// useEffect(() => {
//   const fetchData = async() => {
//      await axios.get('business/businessCategories').then((data) =>{
//       setCategories(data.data.list);
//       console.log(setCategories)
//      });
//   }
//   fetchData();
// }, []);

  const searchHandler = (e) => {
    let val = e.target.value;
    setSearch(val);
    if (val.length > 0) {
      let searchText = val.toLowerCase();
      let tempFilteredData = data.filter((item) => {
        let flag = false;
        //Name
        if (item.Name.toLowerCase().includes(searchText)) {
          flag = true;
        }
        //Email
        if (item.Email.toLowerCase().includes(searchText)) {
          flag = true;
        }
        //Phone
        if (item.Phone.toLowerCase().includes(searchText)) {
          flag = true;
        }
        //Id
        if (item._id.toLowerCase().includes(searchText)) {
          flag = true;
        }
        return flag;
      });
      setFilteredData(tempFilteredData);
    }
    else if (val.length <= 0) {
      setFilteredData(data);
    }
  }

  useEffect(() => {
    const htmlGenerator = () => {
      const { dataColumns, dataRows } = businessTableData(filteredData);
      setRows(dataRows);
      setColumns(dataColumns);
    }
    htmlGenerator();
  }, [filteredData])

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <MDTypography variant="h6" color="white" style={{ width: '50%' }}>
                    Business Directory
                  </MDTypography>
                  <div className="d-flex justify-between">
                    <input type="search" className="form-control  me-2" placeholder="Search Business" value={search} onChange={searchHandler} />
                    <div style={{ width: '100%' }}>
                      <BusinessRegister />
                    </div>
                  </div>
                </div>
              </MDBox>
              <MDBox pt={3}>
                {rows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {loader ? (
                      <div className="w-100 text-center mb-4">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="text-center mb-4">No Business added!</div>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BusinessTables;
