import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import axios from "../../utils/axios";
import { toast, ToastContainer } from 'react-toastify';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Categories from './categoriesList';
import UsaStates from './usaStates';
import Compressor from 'compressorjs';


function businessUpdateModal({ business }) {
  var [show, setShow] = useState(false);
  var [name, setName] = useState(business.Name);
  var [email, setEmail] = useState(business.Email);
  var [phone, setPhone] = useState(business.Phone);
  var [website, setWebsite] = useState(business.Website);
  var [street, setStreet] = useState(business.Street);
  var [city, setCity] = useState(business.City);
  var [state, setState] = useState(business.State);
  var [zipcode, setZipcode] = useState(business.Zipcode);
  var [codeRedemption, setCodeRedemption] = useState(business.CodeRedemptions);
  var [view, setViews] = useState(business.Views);
  var [viewFees, setViewFees] = useState(business.ViewFees);

  // var [bCategories, setBCategories] = useState(business.Categories);
  var [categories, setCategories] = useState(business.Categories)
  var [country, setCountry] = useState('USA');
  var [file, setFile] = useState(null);
  var handleClose = () => setShow(false);
  var handleShow = () => setShow(true);

  // business.Categories.toString().split(",").map((category, index) => {
  //   categories.push(category);
  // })
  // console.log(categories);

  const onImageChange = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      // console.log(img);
      new Compressor(img, {
        quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          setFile(compressedResult);
          // console.log('img compressesd', compressedResult.size/1024/1024)
        }
      });
      // setFile(img);
    }
  };
  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    // we'll set the input value using our setInputValue
    setPhone(formattedPhoneNumber);
  };

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;
    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");
    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;
    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleSubmit = (event) => {
    const formData = new FormData();
    var updatedData = {};
    if (name == '') {
      toast('Name required!')
      // } else if (email == '') {
      //   toast('Email required!')
      // } else if (!email.includes('@')) {
      //   toast('Please provide a valid email!')
      // } else if (website == '') {
      //   toast('Website required!')
      // } else if (phone == '') {
      //   toast('Phone number required!')
      // } else if (address == '') {
      //   toast('Address required!')
    } else {
      email = email.trim()
      website = website.trim();
      phone = phone.trim();
      street = street.trim();
      city = city.trim();
      state = state.trim();
      zipcode = zipcode.trim();
      // country = country.trim();

      if (email == "") {
        email = "NA";
      }
      if (website == "") {
        website = "NA";
      } if (phone == "") {
        phone = "NA";
      } if (street == "") {
        street = "NA";
      } if (city == "") {
        city = "NA";
      } if (state == "") {
        state = "NA";
      } if (zipcode == "") {
        zipcode = "NA";
      }
      // if (country == "") {
      //   country = "NA";
      // }

      const json = {
        businessName: name,
        businessEmail: email,
        businessWebsite: website,
        businessPhone: phone,
        businessStreet: street,
        businessCity: city,
        businessState: state,
        businessZipcode: zipcode,
        businessCategories: categories,
        businessCountry: 'USA',
      };

      // Append all properties of the `user` object to the form
      for (const [key, value] of Object.entries(json)) {
        formData.append(key, value);
      }
      if (file != null) {
        formData.append("file", file);
        updatedData['Image'] = URL.createObjectURL(file);
      } else {
        updatedData['Image'] = business.Image;
      }
      axios.put(
        "business/updateBusinessById/" + business._id, formData, {
        headers: {
          // Multer only parses "multipart/form-data" requests
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          console.log(response);
          toast("Business details updated successfully!");
          updatedData['Name'] = name;
          updatedData['Email'] = email;
          updatedData['Website'] = website;
          updatedData['City'] = city;
          updatedData['State'] = state;
          updatedData['Zipcode'] = zipcode;
          updatedData['Country'] = country;
          updatedData['Street'] = street;
          updatedData['Phone'] = phone;
          updatedData['CodeRedemptions'] = codeRedemption;
          updatedData['Views'] = view;
          updatedData['ViewFees'] = viewFees;


          // updateHandler(updatedData);
          setShow(false);
          setTimeout(() => {
            location.reload();
          }, 500);
        })
        .catch((e) => {
          toast("Business details could not be updated!" + e);
          setTimeout(() => {
            location.reload();
          }, 500);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      {/* <a className="dropdown-item" href="#" onClick={handleShow}>Edit</a> */}
      <MDButton color='info' style={{marginTop:'15px'}} onClick={handleShow}>Edit</MDButton>

      <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: '1200' }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label> Business Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Website</Form.Label>
                  <Form.Control type="text" placeholder="Enter Website" value={website} onChange={(e) => setWebsite(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Phone</Form.Label>
                  <Form.Control type="text" placeholder="Enter Phone" value={phone} onChange={(e) => handleInput(e)} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Street</Form.Label>
                  <Form.Control type="text" placeholder="Enter Street" value={street} onChange={(e) => setStreet(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" placeholder="Enter City" value={city} onChange={(e) => setCity(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>State</Form.Label>
                  <UsaStates handler={setState} state={state} />
                  {/* <Form.Control type="text" placeholder="Enter State" value={state} onChange={(e) => setState(e.target.value)} /> */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>ZipCode</Form.Label>
                  <Form.Control type="text" placeholder="Enter ZipCode" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Country</Form.Label>
                  <Form.Control type="text" disabled placeholder="USA" value={country} onChange={(e) => setCountry(e.target.value)} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Image</Form.Label>
                  <Form.Control type="file" accept='image/png, image/jpeg' placeholder="Add Logo" onChange={onImageChange} />
                </Form.Group>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col>

                <label for="recipient-name" className="col-form-label">Choose Categories:</label>
                <Categories handler={setCategories} />

                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Categories</Form.Label>
                  <Form.Control type="text"  value={Categories} onChange={(e) => setBCategories(e.target.value)} />
                </Form.Group> */}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default businessUpdateModal;