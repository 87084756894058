

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";

export default function data(services) {
  const [serviceUIElements, setServiceUIElements] = useState([]);
  const navigate = useNavigate();

  // console.log("Floors props:",floors);
  useEffect(() => {
    const FetchData = () => {
      if (services !== undefined && services.length > 0) {
        const dataTemp = services.map((service) => ({
          name: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {service.serviceNotes}
            </MDTypography>
          ),
          serviceId: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {service._id}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDButton onClick={() => {
                navigate('/property/floor/room/service', {
                  state: service
                });
              }}>View</MDButton>
            </MDBox>
          ),
        }));
        setServiceUIElements(dataTemp);
      }
      else {
        // console.log('no service')
      }
      // console.log('🔴 Error:',serviceUIElements);
    };
    FetchData();
  }, []);

  return {
    serviceColumns: [
      { Header: "service name", accessor: "name", width: "15%", align: "left" },
      { Header: "service Id", accessor: "serviceId", width: "65%", align: "center" },
      { Header: "status", accessor: "status", width: "20%", align: "center" },
    ],
    serviceRows: serviceUIElements,
  };
}