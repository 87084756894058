
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";

// Images
// import team2 from "assets/images/team-2.jpg";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import BusinessUpdateModal from "layouts/modals/businessUpdateModal";
import SponsorCheckbox from "./SponsorCheckbox";
import ButtonNavigator from "./ButtonNavigator";
import {
  FaTrash
} from "react-icons/fa";
import imageUrl from "utils/imageUrl";

export default function data(data) {
  // const [status, setStatus] = useState(false);



  const handleCLick = (event, id) => {

    axios.delete("business/deleteBusinessbyid/" + id).then((dataD) => {
      // setTotalUsers(dataD.data.Result.length);
    });
    toast("Business deleted sucessfully!");
    setTimeout(() => {
      location.reload();
    }, 300);
  }


  const handleCheckbox = (id, val) => {
    // event.preventDefault();
    const json = {
      "sponsor": val
    }
    axios.put(
      `business/sponsorupdate/${id}`, json
    ).then((sponsorupdate) => {
    });
  }
  const Name = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={Name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  var business = [];
  if (data.length > 0) {
    business = data.map((business, index) => {
      var checked = {};
      if (business.sponsor == "1") {
        checked['checked'] = 'checked'
      };
      return (
        {
          name: <Name image={`${imageUrl}${business.Image}`} name={business.Name} />,
          // status: (
          //   // <div class="dropdown">
          //   //   <MDButton class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" color="info">Options</MDButton>
          //   //   <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          //   //     <BusinessUpdateModal business={business} />
          // <li><a class="dropdown-item" href="#" onClick={event => handleCLick(event, business._id)}>Delete</a></li>
          //   //   </ul>
          //   // </div>
          //   // <MDButton class="btn btn-primary" type="button">View</MDButton>
          //   <Link className="btn btn-primary" to={{
          //     pathname: "/businessDetail",
          //     state: {
          //       business: business
          //     }
          //   }}>View</Link>
          // ),

          options: (
            <>
              <ButtonNavigator business={business} />
              <MDButton className="btn" type="button" onClick={event => handleCLick(event, business._id)}><FaTrash /></MDButton>
            </>
          ),
          sponsor: (
            <SponsorCheckbox id={business._id} checked={business.sponsor} handleCheckbox={handleCheckbox} />
          ),
          email: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {business.Email}
            </MDTypography>
          ),
          phone: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {business.Phone}
            </MDTypography>
          ),
          advertisementCode: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {business.advertisementCode}
            </MDTypography>
          ),
          businessId: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {business._id}
            </MDTypography>
          ),
        }
      )
    });
  }


  return {
    dataColumns: [
      { Header: "name", accessor: "name", width: "15%", align: "left" },
      { Header: "email", accessor: "email", width: "20%", align: "center" },
      { Header: "phone", accessor: "phone", width: "10%", align: "center" },
      { Header: "business Id", accessor: "businessId", align: "center" },
      { Header: "sponsor code", accessor: "advertisementCode", align: "center" },
      { Header: "sponsor", accessor: "sponsor", align: "center" },
      { Header: "options", accessor: "options", align: "center" },
    ],

    dataRows: business,
  };
}
