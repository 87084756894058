// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";

// Data
import propertyTableData from "layouts/properties/data/authorsTableData";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../utils/axios";


function PropertiesTables() {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');

  const handleLoader = (val) => {
    setLoader(val);
  };
  const searchHandler = (e) => {
    let val = e.target.value;
    setSearch(val);
    if (val.length > 0) {
      var tempFilteredData = data.filter((item) => {
        var flag = false;
        //Name
        if (item.propertyName.toLowerCase().includes(search.toLowerCase())) {
          flag = true;
        }
        //State
        if (item.propertyState.toLowerCase().includes(search.toLowerCase())) {
          flag = true;
        }
        //ZipCode
        if (item.propertyZipCode.toLowerCase().includes(search.toLowerCase())) {
          flag = true;
        }
        if (item.propertyCity.toLowerCase().includes(search.toLowerCase())) {
          flag = true;
        }
        //email
        if (item.user!=null && item.user.Email.toLowerCase().includes(search.toLowerCase())) {
          flag = true;
        }
        return flag;
      });
      setFilteredData(tempFilteredData);
      console.log('Data:', tempFilteredData);
    }
    else if (val.length <= 0) {
      setFilteredData(data);
    }
  }
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  // const { columns: pColumns, rows: pRows } = projectsTableData();

  //Fetch Data aand Store in the data Varible
  useEffect(() => {
    // let isMounted = true;
    const fetchData = () => {
      axios.get("property/getProperties").then(async (dataP) => {
        if (dataP != null) {
          var allProperties = dataP.data;
          console.log('All Properties:', allProperties);
          var newPropsList = [];
          await Promise.all(allProperties.map(async (item) => {
            await axios.get("user/getuserbyid/" + item.userId).then((dataB) => {
              // console.log('User:', dataB.data.Data);
              const tempData = dataB.data.Data;
              let withUser = { ...item, user: tempData };
              newPropsList.push(withUser);
            });
          }));
          // console.log('🧨 New Props List:', newPropsList);
          setData(newPropsList);
          setFilteredData(newPropsList);
          const { tempColumns, tempRows } = propertyTableData(newPropsList);
          setRows(tempRows);
          setColumns(tempColumns);
        } else {
          setData([]);
        }
      }).catch((err) => {
        // console.log('🔴Error: ', err);
      }).finally(() => setLoader(false));
    }
    fetchData();
    // return () => { isMounted = false };
  }, []);

  // useEffect(()=> {
  //   data.map((property)=> {
  //     console.log(property.userId)
  //     const fetchUser = () => {
  //       axios.get("user/getuserbyid/" + property.userId).then((dataB) => {
  //         const tempData = dataB.data.Data.Email;
  //         console.log('㊗️ Data:', tempData);
  //         // setEmail(tempData);
  //         // setFilteredData(tempData);
  //       });
  //     }
  //     fetchUser();
  //   })
  // }, [])

  useEffect(() => {
    const updateData = () => {
      if (filteredData.length > 0) {
        const { tempColumns, tempRows } = propertyTableData(filteredData);
        setRows(tempRows);
        setColumns(tempColumns);
      } else {
        setRows([]);
      }
    }
    updateData();
  }, [filteredData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div className="d-flex justify-content-between">
                  <MDTypography variant="h6" color="white">
                    Properties
                  </MDTypography>
                  <div className="">
                    <input type="search" className="form-control" placeholder="Search Property" value={search} onChange={searchHandler} />
                  </div>
                </div>
              </MDBox>
              <MDBox pt={3}>
                {rows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {loader ? (
                      <div className="w-100 text-center mb-4">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="text-center mb-4">No properties added!</div>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PropertiesTables;