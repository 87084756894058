import React from 'react'

function UserData({ user }) {

    return (
        <>
            {
                user != null ?
                user.Email
                :
                ''
            }
        </>
    )
}

export default UserData