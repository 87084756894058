import React, { useState } from 'react';
import MDButton from 'components/MDButton';
import MDBox from "components/MDBox";
import axios from "../../utils/axios"
import { toast, ToastContainer } from 'react-toastify';
import ImportBusinessDirectory from "layouts/modals/businessImportModal";
import UsaStates from './usaStates';
import Categories from './categoriesList';

function businessRegister() {
    var [name, setName] = useState("");
    var [email, setEmail] = useState("");
    var [phone, setPhone] = useState("");
    var [website, setWebsite] = useState("");
    var [street, setStreet] = useState("");
    var [city, setCity] = useState("");
    var [state, setState] = useState("");
    var [zipcode, setZipcode] = useState("");
    var [country, setCountry] = useState("");
    var [file, setFile] = useState(null);
    var [categories, setCategories] = useState([]);

    const [addAd, setAddAd] = useState(false);
    const [adImage, setAdImage] = useState('');
    const [adImageFile, setAdImageFile] = useState(null);

    const handleClose = () => {
        setAddAd(false);
        setAdImage(null);
    }

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            setFile(img);
        }
    };

    const handleInput = (e) => {
        // this is where we'll call the phoneNumberFormatter function
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // we'll set the input value using our setInputValue
        setPhone(formattedPhoneNumber);
    };

    function formatPhoneNumber(value) {
        // if input value is falsy e.g. if the user deletes the input, then just return
        if (!value) return value;
        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, "");
        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length;
        // we need to return the value with no formatting if it's less than four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;
        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        // finally, if the phoneNumberLength is greater than seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }

    const onAdImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            var imageURL = URL.createObjectURL(img);
            setAdImage(imageURL);
            setAdImageFile(img);
        }
    };

    const handleSubmit = (event) => {
        if (name == '') {
            toast('Name required!')
            // } else if (email == '') {
            //   toast('Email required!')
            // } else if (!email.includes('@')) {
            //   toast('Please provide a valid email!')
            // } else if (website == '') {
            //   toast('Website required!')
            // } else if (phone == '') {
            //   toast('Phone number required!')
            // } else if (address == '') {
            //   toast('Address required!')
        } else {
            name = name.trim();
            email = email.trim();
            website = website.trim();
            phone = phone.trim();
            street = street.trim();
            city = city.trim();
            state = state.trim();
            zipcode = zipcode.trim();
            // country = country.trim();

            if (email == "") {
                email = "NA";
            }
            if (website == "") {
                website = "NA";
            }
            if (phone == "") {
                phone = "NA";
            }
            if (street == "") {
                street = "NA";
            }
            if (city == "") {
                city = "NA";
            }
            if (state == "") {
                state = "NA";
            }
            if (zipcode == "") {
                zipcode = "NA";
            }
            if (categories == "") {
                categories = "NA";
            }
            // if (country == "") {
            //   country = "NA";
            // }

            const json = {
                businessName: name,
                businessEmail: email,
                businessWebsite: website,
                businessPhone: phone,
                businessStreet: street,
                businessCity: city,
                businessState: state,
                businessZipcode: zipcode,
                businessCategories: categories,
                businessCountry: 'USA',
            };
            const formData = new FormData();

            // Append all properties of the `user` object to the form
            for (const [key, value] of Object.entries(json)) {
                formData.append(key, value);
            }
            if (file) {
                formData.append("file", file);
            }
            if (adImageFile) {
                formData.append("file", adImage);
            }

            axios.post(
                "business/registerBusiness",
                formData,
                {
                    headers: {
                        // Multer only parses "multipart/form-data" requests
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((response) => {
                    if (response.data.Message === "Business Registered Successfully") {
                        toast("Business Registered Successfully");

                        if (adImageFile) {
                            const adFormData = new FormData();
                            adFormData.append('file', adImageFile);
                            adFormData.append('SponsorCode', response.data.Data.advertisementCode);
                            axios.post("ad/adregister", adFormData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                                .then((response) => {
                                    toast("Ad Published successfully");
                                })
                                .catch((e) => {
                                    toast("Ad Could not be published!");
                                });
                        }
                        setTimeout(() => {
                            handleClose();
                            location.reload();
                        }, 1000);
                    } else if (response.data.Message === "Business already Registered against this Name") {
                        toast("Business Already Registered against this Name!");
                    } else {
                        toast('Could not register the Business');
                    }
                })
                .catch(() => {
                    toast("Something went wrong!");
                });
        }
    };

    const viewLogsHandler = () => {
        var baseURL = axios.defaults.baseURL;
        window.open(`${baseURL}uploads/logs.txt`, '_blank');
    }
    return (
        <>
            <ToastContainer />
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div className="d-flex justify-end">
                    <MDButton color="dark" width="100%" data-bs-toggle="modal" className='me-2'
                        data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">Add Business</MDButton>
                    <ImportBusinessDirectory />
                    <MDButton color="dark" width="100%" className='me-2' data-bs-whatever="@getbootstrap"
                        onClick={viewLogsHandler}>View Logs</MDButton>
                </div>
            </div>
            <div className="modal fade in" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true" style={{ zIndex: '1200' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Business</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label" placeholder="Name*"
                                            fullWidth>Business Name:</label>
                                        <input className="form-control" placeholder='Name' type="name" name="name"
                                            value={name} onChange={(event) => setName(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">Business Email:</label>
                                        <input className="form-control" placeholder='example@gmail.com' type='email'
                                            name='email' value={email}
                                            onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">Business Website:</label>
                                        <input className="form-control" placeholder='www.example.com' type='text'
                                            name='website' value={website}
                                            onChange={(event) => setWebsite(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">Business Phone:</label>
                                        <input className="form-control" placeholder='(123) 456-7890' type='phone'
                                            name='phone' onChange={(e) => handleInput(e)} value={phone} />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h5 className='mt-3'>Categories</h5>
                                    <hr />
                                </div>
                                <div className="mb-6">
                                    <label for="recipient-name" className="col-form-label">Choose Categories:</label>
                                    <Categories handler={setCategories} />
                                </div>
                                <div className="col-md-12">
                                    <h5 className='mt-5'>Business Address</h5>
                                    <hr />
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">Street:</label>
                                        <input className="form-control" placeholder='Street' type='street' name='street'
                                            value={street} onChange={(event) => setStreet(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">City:</label>
                                        <input className="form-control" placeholder='City' type='city' name='city'
                                            value={city} onChange={(event) => setCity(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">State:</label>
                                        <UsaStates handler={setState} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">ZIP CODE:</label>
                                        <input className="form-control" placeholder='ZipCode' type='zipcode'
                                            name='zipcode' value={zipcode}
                                            onChange={(event) => setZipcode(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">Country:</label>
                                        <input className="form-control" disabled placeholder='USA' type='country'
                                            name='country' value={country}
                                            onChange={(event) => setCountry(event.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label for="recipient-name" className="col-form-label">Add Business Logo</label>
                                        <input className="form-control" type='file' accept='image/png, image/jpeg'
                                            name='image' onChange={onImageChange} />
                                    </div>
                                </div>
                            </div>
                            {
                                addAd ?
                                    <div>
                                        <div className="col-md-12">
                                            <h5> Add Advertisement</h5>
                                            <hr />
                                            <p style={{ color: 'red' }}>*Image should be Landscape Size 120*300</p>
                                            <MDBox
                                                mx={2}
                                                mt={3}
                                                py={3}
                                                px={2}
                                            // variant="gradient"
                                            // bgColor="white"
                                            // borderRadius="lg"
                                            // coloredShadow="info"
                                            >
                                            </MDBox>
                                        </div>
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <input type='file' className='form-control'
                                                        accept='image/png, image/jpeg' onChange={onAdImageChange} />
                                                </div>
                                                <div className='col-md-6' style={{ textAlign: 'right' }}>
                                                    <img src={adImage} className='w-100' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='text-end'>
                                        <button className='btn btn-primary' onClick={() => setAddAd(!addAd)}>Add
                                            Advertisement
                                        </button>
                                    </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add Business
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default businessRegister;