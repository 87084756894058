

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";


export default function data(rooms) {
  const navigate = useNavigate();
  const [roomUIElements, setRoomUIElements] = useState([]);
  // console.log("romms:",rooms);
  useEffect(() => {
    const FetchData = () => {
      if (rooms !== undefined && rooms.length > 0) {
        const dataTemp = rooms.map((room) => ({
          name: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {room.roomName}
            </MDTypography>
          ),
          roomId: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {room._id}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDButton onClick={() => {
                navigate('/property/floor/room', {
                  state: room
                });
              }}>View</MDButton>
            </MDBox>
          ),

        }));
        setRoomUIElements(dataTemp);
      }
    };
    FetchData();
  }, []);

  return {
    columns: [
      { Header: "room name", accessor: "name", width: "15%", align: "left" },
      { Header: "room Id", accessor: "roomId", width: "65%", align: "center" },
      { Header: "status", accessor: "status", width: "20%", align: "center" },
    ],
    rows: roomUIElements,
  };
}
