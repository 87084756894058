import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// react-router-dom components
import { Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../../utils/axios";

function Login() {
  // const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleLoader = (val) => {
    setLoader(val);
  };
  const [showHomePage, setShowHomepage] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLoader(true);
    const json = { Email: email, Password: password };
    axios
      .post("admin/login", json)
      .then((response) => {
        // console.log(response);
        if (response.data.Admin) {
          handleLoader(false);
          toast("Admin logged in successfully");
          window.sessionStorage.setItem("isLoggedIn", "true");
          setTimeout(() => setIsLoggedIn(true), 1000);
        } else {
          handleLoader(false);
          setPassword("");
          setEmail("");
          toast(response.data.msg);
          // toast("Only Admin is allowed to access the portal!");
        }
      })
      .catch((err) => {
        console.log(err);
        handleLoader(false);
        toast(`Something went wrong!`);
      });
  };
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <BasicLayout image={bgImage}>
      <ToastContainer />
      {
        showHomePage ?
          <div className='c-bg-home'>
            {/* <img src={signInImg} className='c-bg' /> */}
            <div className='c-overlay'>
              <div className='heading text-center'>
                {/* <img src={logo} className='mb-2' width={70}/> */}
                <h2 className='mx-auto text-center text-white'>Welcome to Total Home App</h2>
                <p className='text-white h6'>Educating & Empowering Home Owners <br /> to Build Value in Their Homes</p>
              </div>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-8 mx-auto text-center'>
                    <a href='https://business.totalhomecrm.com/' className='btn btn-success me-2'>Business login</a>
                    <button to='/sign-in' className='btn btn-danger' onClick={() => setShowHomepage(false)}>Admin Login</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Sign In
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                Enter your Email and Password
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="email"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder="Email address *"
                    fullWidth
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="password"
                    name="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Password *"
                    fullWidth
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                    sign in
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  {loader ? (
                    <div className="w-100 text-center mb-4">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <MDTypography variant="button" color="text">
                      Authorised Personnel Login
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
      }
    </BasicLayout>
  );
}

export default Login;
