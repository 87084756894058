
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import floorsTableData from "layouts/property/data/floorsTableData";
import genericProductsTableData from "layouts/property/data/genericProductsTableData";
import serviceTableData from "layouts/property/data/serviceTableData"

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import BCard from 'react-bootstrap/Card';
import axios from "../../utils/axios";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { CiImageOff } from "react-icons/ci";
import imageUrl from "utils/imageUrl";

function PropertyData(props) {

  useEffect(() => {
    const fetchData = () => {
      const id = state.userId;
      console.log(id)
      axios.get('user/getuserbyid/' + id).then((data) => {
        setUser(data.data.Data.Name)
      })
    }
    fetchData();
  }, [])

  const [user, setUser] = useState('');
  const { state } = useLocation();
  const [floors, setFloors] = useState(state.floors);
  const { columns, rows } = floorsTableData(floors);
  const [genericProducts, setGenericProducts] = useState(state.genericProducts);
  const { genProdColumns, genProdRows } = genericProductsTableData(genericProducts);
  const [services, setServices] = useState(state.services);
  const { serviceColumns, serviceRows } = serviceTableData(services);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
        <BCard>
          <BCard.Body>
            <Row>
              <Col md="4">
                <div className="text-center">
                  {(state.propertyImage !== "") ?
                    (<img src={`${imageUrl}${state.propertyImage}`} className="w-100" style={{
                      height: '300px',
                      objectFit: 'cover'
                    }} />
                    ) : (
                      <CiImageOff
                        style={{
                          fontSize: "10rem",
                          marginTop: "50px",
                          marginLeft: "10px",
                        }} />)
                  }
                </div>
              </Col>
              <Col md="8">
                <h6>Property Name:</h6><b style={{ fontSize: '1.5rem' }}>{state.propertyName}</b>
                <hr />
                <Row>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Property ID:</b><br></br> {state._id}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>User Name:</b><br></br> {user}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Property Type:</b> <br></br>{state.propertyType}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Building Type:</b> <br></br> {state.buildingType}
                      </p></Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="3">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Country:</b><br></br> {state.propertyCountry}
                      </p>
                    </Col>
                    <Col md="3">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>State:</b><br></br> {state.propertyState}
                      </p>
                    </Col>
                    <Col md="3">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>City:</b><br></br> {state.propertyCity}
                      </p>
                    </Col>
                    <Col md="3">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>ZIPCODE:</b><br></br> {state.propertyZipCode}
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </BCard.Body>
        </BCard>
      </Container>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Floors
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  rows.length > 0 ?
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    :
                    <>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                      <div className="text-center mb-4">
                        No floors added!
                      </div>
                    </>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Items
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  genProdRows.length > 0 ?
                    <DataTable
                      table={{ columns: genProdColumns, rows: genProdRows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    :
                    <>
                      <DataTable
                        table={{ columns: genProdColumns, rows: genProdRows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                      <div className="text-center mb-4">
                        No generic products added!
                      </div>
                    </>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Services
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {
                  serviceRows.length > 0 ?
                    <DataTable
                      table={{ columns: serviceColumns, rows: serviceRows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    :
                    <>
                      <DataTable
                        table={{ columns: serviceColumns, rows: serviceRows }}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />
                      <div className="text-center mb-4">
                        No Services added!
                      </div>
                    </>
                }
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PropertyData;