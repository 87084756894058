import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import MDTypography from "../../../components/MDTypography";

export default function data(handleLoader, date) {
  const [history, setHistory] = useState([]);
  // console.log(date);

  useEffect(() => {
    const FetchData = () => {
      const todayDate = date;
      // const todayDate = new Date();
      let day = todayDate.getDate();
      let month = todayDate.getMonth() + 1;
      const year = todayDate.getFullYear();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      const jsonDate = `${month}-${day}-${year}`;
      // console.log(jsonDate);
      const json = { historyDate: jsonDate };
      const formatTime = (val) => {
        const histDate = new Date(val);
        const time = histDate.toLocaleTimeString();
        return time;
      };
      axios.post("history/getHistoryByDate", json).then((historyData) => {
        // console.log(historyData);
        if (historyData.data.Result) {
          const dataTemp = historyData.data.Data.history.map((hist) => ({
            userEmail: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {hist.userEmail}
              </MDTypography>
            ),
            time: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {formatTime(hist.date)}
              </MDTypography>
            ),
            propertyName: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {hist.propertyName}
              </MDTypography>
            ),
          }));
          setHistory(dataTemp);
        } else {
          setHistory([]);
        }
        handleLoader(false);
      });
    };
    FetchData();
  }, [date]);

  return {
    columns: [
      { Header: "user email", accessor: "userEmail", width: "33%", align: "left" },
      { Header: "time", accessor: "time", width: "33%", align: "center" },
      { Header: "property name", accessor: "propertyName", width: "33%", align: "center" },
    ],
    rows: history,
  };
}
