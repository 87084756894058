import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import {
  FaEye
} from "react-icons/fa";

function ButtonNavigator({ business }) {
  const navigate = useNavigate();
  return (
    // <MDBox ml={-1}>
    //   <MDButton className="btn btn-primary" color='info' onClick={() => {
    //     navigate('/businessDetail', {
    //       state: business
    //     });
    //   }}>View</MDButton>
    // </MDBox>
    <MDButton className="btn" type="button"
      onClick={() => {
        navigate(`/businessDetail/${business._id}`);
      }}
    ><FaEye /></MDButton>


  )
}

export default ButtonNavigator
