import {useEffect, useState} from "react";
import axios from "../../../utils/axios";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import {toast} from "react-toastify";


export default function CredentialTableData(handleLoader) {
    const [status, setStatus] = useState('');
    const [request, setRequest] = useState([]);

    const handleSubmit = (event, id, active) => {
        // event.preventDefault();
        if (active === 'delete') {
            axios.delete(
                `credentialsRequest/deleteCredentialsRequest/${id}`
            ).then((dataD) => {
                toast('Request deleted');
                setStatus('')
            });
        } else if (active === true) {
            const json = {
                "status": 'approved'
            }
            axios.put(
                `credentialsRequest/updateRequestStatus/${id}`, json
            ).then((statusUpdate) => {
                toast('Request Approved')
                // setStatus(!status);
            })
        } else {
            const json = {
                "status": 'declined'
            }
            axios.put(
                `credentialsRequest/updateRequestStatus/${id}`, json
            ).then((statusUpdate) => {
                // setStatus(!status);
                toast('Request declined');
            })
        }
        setTimeout(() => {
            location.reload();
        }, 500);
    };

    useEffect(() => {
        const FetchData = () => {
            axios.get("credentialsRequest/allCredentialsRequests").then((dataRequest) => {
                if (dataRequest.data.Data !== undefined) {
                    const dataTemp = dataRequest.data.Data.map((request, index) => ({

                        BName: (
                            <MDTypography
                                component="a"
                                href="#"
                                variant="caption"
                                color="dark"
                                fontWeight="medium"
                            >
                                {request.BusinessName}
                            </MDTypography>
                        ),
                        Options: (
                            <div className="dropdown">
                                <MDButton class="btn btn-outline-dark" type="button" id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown" aria-expanded="false" color="dark">Change
                                    Status</MDButton>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item"
                                           onClick={event => handleSubmit(event, request._id, true)}>Approve</a></li>
                                    <li><a className="dropdown-item"
                                           onClick={event => handleSubmit(event, request._id, false)}>Decline</a></li>
                                    <li><a className="dropdown-item"
                                           onClick={event => handleSubmit(event, request._id, 'delete')}>Delete</a></li>
                                </ul>
                            </div>
                        ),
                        Email: (
                            <MDTypography
                                component="a"
                                href="#"
                                variant="caption"
                                color="dark"
                                fontWeight="medium"
                            >
                                {request.Email}
                            </MDTypography>
                        ),
                        Phone: (
                            <MDTypography
                                component="a"
                                href="#"
                                variant="caption"
                                color="dark"
                                fontWeight="medium"
                            >
                                {request.Phone}
                            </MDTypography>
                        ),

                        Status: (
                            <MDTypography
                                component="a"
                                href="#"
                                variant="caption"
                                color={request.Status === 'approved' ? 'success' : (request.Status === 'pending' ? 'warning' : 'error')}
                                fontWeight="medium"
                            >
                                {request.Status === 'approved' ? 'APPROVED' : (request.Status === 'pending' ? 'PENDING' : 'DECLINED')}
                            </MDTypography>
                        ),
                    }));
                    setRequest(dataTemp);
                }
                handleLoader(false);
            });
        };
        FetchData();
    }, [status]);

    return {
        columns: [
            {Header: "Business Name", accessor: "BName", width: "10%", align: "left"},
            {Header: "Email", accessor: "Email", width: "10%", align: "center"},
            {Header: "Phone Number", accessor: "Phone", width: "30%", align: "center"},
            {Header: "Status", accessor: "Status", width: "10%", align: "center"},
            {Header: "Options", accessor: "Options", width: "20%", align: "center"},
        ],

        rows: request,
    };
}