import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import axios from "../../utils/axios";
import { toast, ToastContainer } from 'react-toastify';
import { Container, Row, Col, Form } from 'react-bootstrap';
import {
  FaEye
} from "react-icons/fa";

function ViewAddModal({ image }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setImage('');
    setFile(null);
  }
  const handleShow = () => setShow(true);
  return (
    <>
      <MDButton className="btn" type="button" onClick={handleShow}><FaEye /></MDButton>
      <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: '1200' }}>
        <Modal.Header closeButton>
          <Modal.Title>Advertisement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <img src={image} />
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewAddModal;