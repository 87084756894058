

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";

export default function Data(genericProducts) {
  const [genProdUIElements, setGenProdUIElements] = useState([]);
  const navigate = useNavigate();
  // console.log("generic products",genericProducts);
  useEffect(() => {
    const FetchData = () => {
      if (genericProducts !== undefined && genericProducts.length > 0) {
        const dataTemp = genericProducts.map((genProd) => ({
          name: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {genProd.genProductName}
            </MDTypography>
          ),
          genProdId: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {genProd._id}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDButton onClick={() => {
                navigate('/property/item', {
                  state: genProd
                });
              }}>View</MDButton>
            </MDBox>
          ),
        }));
        setGenProdUIElements(dataTemp);
      }
    };
    FetchData();
  }, []);

  return {
    genProdColumns: [
      { Header: "Item name", accessor: "name", width: "20%", align: "left" },
      { Header: "Item Id", accessor: "genProdId", width: "60%", align: "center" },
      { Header: "status", accessor: "status", width: "20%", align: "center" },
    ],
    genProdRows: genProdUIElements,
  };
}