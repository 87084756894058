// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import imageUrl from "utils/imageUrl";

// Images
// import team2 from "assets/images/team-2.jpg";
import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import ViewAddModal from "../../modals/ViewAddModal";

export default function Data(handleLoader) {
  const [status, setStatus] = useState(false);
  const [advertisement, setAdvertisement] = useState([]);

  const handleCLick = (event, id) => {
    axios
      .delete(`ad/addelete/${id}`)
      .then((dataD) => {
        toast("Ad deleted successfully!");
        setStatus(!status);
      })
      .catch((err) => {
        toast("Ad could not be deleted!");
      });
  };
  useEffect(() => {
    const FetchData = () => {
      axios.get("ad/getAllAd").then((dataV) => {
        if (dataV.data.Data !== undefined) {
          const dataTemp = dataV.data.Data.map((advertisement, index) => ({

            Image: <Name image={`${imageUrl}${advertisement.Image}`} name="" />,
            Options: (
              <div class="dropdown">
                <MDButton
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  color="info"
                >
                  Options
                </MDButton>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a
                      class="dropdown-item"
                      href="#"
                      onClick={(event) => handleCLick(event, advertisement._id)}
                    >
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            ),
            View: <ViewAddModal image={`${imageUrl}${advertisement.Image}`} />,
            AdID: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {advertisement._id}
              </MDTypography>
            ),

            SPONSOR: (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="dark"
                fontWeight="medium"
              >
                {advertisement.SponsorCode}
              </MDTypography>
            ),


          }));
          setAdvertisement(dataTemp);
        }
        handleLoader(false);
      });
    };
    FetchData();
  }, [status]);

  function Name({ image }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDAvatar src={image} />
        <MDBox ml={2} lineHeight={1} />
      </MDBox>
    );
  }
  return {
    columns: [
      { Header: "Image", accessor: "Image", width: "10%",align: "left"},
      { Header: "View", accessor: "View", width: "10%",align: "center" },
      { Header: "Ad ID", accessor: "AdID", width: "30%",align: "center" },
      { Header: "Sponsor Code", accessor: "SPONSOR", width: "10%", align: "center"},
      { Header: "Options", accessor: "Options", width: "20%", align: "center"},
    ],

    rows: advertisement,
  };
}
