// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import {useEffect, useState} from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../utils/axios";

function Dashboard() {
    const [totalProperties, setTotalProperties] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalBusiness, setTotalBusiness] = useState(0);
    const [totalIos, setTotalIos] = useState(0);
    const [totalAndroid, setTotalAndroid] = useState(0);
    const [chartData, setChartData] = useState({
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        datasets: {label: "Users", data: []},
    });
    const [chartBusinessData, setChartBusinessData] = useState({
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        datasets: {label: "Businesses", data: []},
    });
    const [chartPropertyData, setChartPropertyData] = useState({
        labels: ["M", "T", "W", "T", "F", "S", "S"],
        datasets: {label: "Properties", data: []},
    });

    const [loader, setLoader] = useState(true);
    const today = new Date();
    let hour = today.getHours();
    let minutes = today.getMinutes();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    const year = today.getFullYear();
    if (hour < 10) {
        hour = `0${hour}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    const date = `${month}-${day}-${year}`;
    const time = `${hour}:${minutes}`;
    const timeStatement = "Last Updated at ";

    useEffect(() => {
        const FetchData = () => {
            axios.get("user/getAllUser").then((dataU) => {
                setTotalUsers(dataU.data.Data.length);
            });

            setTimeout(setLoader(false), 4000);

            axios.get("user/getchart").then((dataChart) => {
                const dataUsers = dataChart.data.Data[0].users;
                const dataBusiness = dataChart.data.Data[0].BusinessDirectory;
                const dataProperties = dataChart.data.Data[0].properties;
                const onejan = new Date(new Date().getFullYear(), 0, 1);
                const weekNumber = Math.ceil(
                    ((new Date().getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
                );
                dataUsers.map((user) => {
                    if (user.week === weekNumber) {
                        const tempDays = [];
                        tempDays.push(user.days.monday);
                        tempDays.push(user.days.tuesday);
                        tempDays.push(user.days.wednesday);
                        tempDays.push(user.days.thursday);
                        tempDays.push(user.days.friday);
                        tempDays.push(user.days.saturday);
                        tempDays.push(user.days.sunday);

                        setChartData({
                            labels: ["M", "T", "W", "T", "F", "S", "S"],
                            datasets: {label: "Users", data: tempDays},
                        });
                    }
                });
                dataBusiness.map((business) => {
                    if (business.week === weekNumber) {
                        const tempDays = [];
                        tempDays.push(business.days.monday);
                        tempDays.push(business.days.tuesday);
                        tempDays.push(business.days.wednesday);
                        tempDays.push(business.days.thursday);
                        tempDays.push(business.days.friday);
                        tempDays.push(business.days.saturday);
                        tempDays.push(business.days.sunday);

                        setChartBusinessData({
                            labels: ["M", "T", "W", "T", "F", "S", "S"],
                            datasets: {label: "Businesses", data: tempDays},
                        });
                    }
                });
                dataProperties.map((property) => {
                    if (property.week === weekNumber) {
                        const tempDays = [];
                        tempDays.push(property.days.monday);
                        tempDays.push(property.days.tuesday);
                        tempDays.push(property.days.wednesday);
                        tempDays.push(property.days.thursday);
                        tempDays.push(property.days.friday);
                        tempDays.push(property.days.saturday);
                        tempDays.push(property.days.sunday);

                        setChartPropertyData({
                            labels: ["M", "T", "W", "T", "F", "S", "S"],
                            datasets: {label: "Properties", data: tempDays},
                        });
                    }
                });
            });
            axios.get("property/getProperties").then((dataP) => {
                setTotalProperties(dataP.data.length);
            });

            axios.get("business/GetAllBusiness").then((dataB) => {
                // console.log(dataV)
                setTotalBusiness(dataB.data.Data.length);
            });
            axios.get("appInstalls/getInstalls").then((dataI) => {
                // console.log(dataI.data.Data[0].Android);
                setTotalIos(dataI.data.Data[0].iOS);
                setTotalAndroid(dataI.data.Data[0].Android);
            });
        };
        FetchData();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            {loader ? (
                <div className="w-100 text-center mb-4">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <MDBox py={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={6}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="android"
                                    title="Total Android Installs"
                                    count={totalAndroid}
                                    color='secondary'
                                    percentage={{
                                        color: "success",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="apple"
                                    title="Total iOS Installs"
                                    count={totalIos}
                                    color='secondary'
                                    percentage={{
                                        color: "success",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5} mt={2}>
                                <ComplexStatisticsCard
                                    icon="group"
                                    title="Total Users"
                                    count={totalUsers}
                                    percentage={{
                                        color: "success",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5} mt={2}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="engineering"
                                    title="Total Businesses"
                                    count={totalBusiness}
                                    percentage={{
                                        color: "success",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={1.5} mt={2}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="apartment"
                                    title="Total Properties"
                                    count={totalProperties}
                                    percentage={{
                                        color: "success",
                                    }}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                    <MDBox mt={4.5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mb={3}>
                                    <ReportsBarChart
                                        color="info"
                                        title="Daily Users registered"
                                        description={<b>{date}</b>}
                                        date={
                                            <>
                                                {timeStatement}
                                                <strong>{time}</strong>.
                                            </>
                                        }
                                        chart={chartData}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mb={3}>
                                    <ReportsBarChart
                                        color="success"
                                        title="Daily Businesses registered"
                                        description={<b>{date}</b>}
                                        date={
                                            <>
                                                {timeStatement}
                                                <strong>{time}</strong>.
                                            </>
                                        }
                                        chart={chartBusinessData}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <MDBox mb={3}>
                                    <ReportsBarChart
                                        color="dark"
                                        title="Daily Properties registered"
                                        description={<b>{date}</b>}
                                        date={
                                            <>
                                                {timeStatement}
                                                <strong>{time}</strong>.
                                            </>
                                        }
                                        chart={chartPropertyData}
                                    />
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox>
                        <Grid container spacing={3}/>
                    </MDBox>
                </MDBox>
            )}
            <Footer/>
        </DashboardLayout>
    );
}

export default Dashboard;
