import React from 'react'
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link } from 'react-router-dom';
import signInImg from '../../assets/images/building.jpg';
import '../../style.css'
import logo from '../../assets/images/logo-ct.png';
function HomePage({show, onClick}) {
    return (
        <MDBox>
            
        </MDBox>


    )
}

export default HomePage
