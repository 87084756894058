import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../utils/axios";

function AdminLogin({ property }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loader, setLoader] = useState(false);
  const handleLoader = (val) => {
    setLoader(val);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    handleLoader(true);
    const json = { Email: email, Password: password };
    axios
      .post("admin/login", json)
      .then((response) => {
        if (response.data.Admin) {
          handleLoader(false);
          setPassword("");
          setEmail("");
          const historyDate = new Date();
          const currentDate = new Date();
          let day = currentDate.getDate();
          let month = currentDate.getMonth() + 1;
          const year = currentDate.getFullYear();
          if (month < 10) {
            month = `0${month}`;
          }
          if (day < 10) {
            day = `0${day}`;
          }
          const date = `${month}-${day}-${year}`;
          const historyJson = {
            CurrentDate: date,
            UserEmail: email,
            HistoryDate: historyDate,
            PropertyName: property.propertyName,
          };
          // console.log(json);
          axios
            .post("history/postHistory", historyJson)
            .then((histResponse) => {
              // console.log(histResponse);
            })
            .catch((err) => {
              console.log(err);
            });
          navigate("/property", {
            state: property,
          });
        } else {
          handleLoader(false);
          toast(response.data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
        handleLoader(false);
        toast(`Something went wrong!`);
      });
  };
  return (
    <>
      <ToastContainer />
      <Button variant="primary" onClick={handleShow}>
        View
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col">
            <div className="col-md-12">
              <div className="mb-3">
                <label
                  htmlFor="recipient-name"
                  className="col-form-label"
                  placeholder="Name*"
                  fullWidth
                  style={{ textAlign: "left" }}
                >
                  Email:
                </label>
                <input
                  className="form-control"
                  placeholder="Email"
                  type="email"
                  name="name"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="recipient-name" className="col-form-label">
                  Password:
                </label>
                <input
                  className="form-control"
                  placeholder="*********"
                  type="password"
                  name="email"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loader ? (
            <div className="w-100 text-center mb-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div>
              <Button variant="secondary" onClick={handleClose} className="me-2">
                Close
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Login
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminLogin;
