import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import MDButton from "components/MDButton";
import { toast, ToastContainer } from "react-toastify";
import { Container, Row, Col, Form } from "react-bootstrap";
import Papa from "papaparse";
import Spinner from "react-bootstrap/Spinner";
import axios from "../../utils/axios";

function businessImportCSV() {
  const [show, setShow] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [loader, setLoader] = useState(false);
  const handleLoader = (val) => {
    setLoader(val);
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const checkEmptyString = (value) => {
    if (value === "") {
      return "NA";
    }
    return value;
  };
  const saveBusiness = async () => {
    let savedBusiness = 0;
    let unsavedBusiness = 0;
    var date = new Date();
    var log = date.toISOString() + '\n';
    handleLoader(true);
    toast("Importing Business from CSV file.");
    // eslint-disable-next-line array-callback-return
    await Promise.all(businesses.map(async (business) => {
      const name = business.businessName;
      const email = checkEmptyString(business.businessEmail);
      const website = checkEmptyString(business.businessWebsite);
      const phone = checkEmptyString(business.businessPhone);
      const street = checkEmptyString(business.street);
      const city = checkEmptyString(business.city);
      const state = checkEmptyString(business.state);
      const zipcode = checkEmptyString(business.zipcode);
      const country = checkEmptyString(business.country);

      if (name !== "") {
        const json = {
          businessName: name,
          businessEmail: email,
          businessWebsite: website,
          businessPhone: phone,
          businessStreet: street,
          businessCity: city,
          businessState: state,
          businessZipcode: zipcode,
          businessCountry: country,
        };
        const formData = new FormData();

        // Append all properties of the `user` object to the form
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(json)) {
          formData.append(key, value);
        }
        await axios
          .post("business/registerBusiness", formData, {
            headers: {
              // Multer only parses "multipart/form-data" requests
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            if (response.data.Message === "Business Registered Successfully") {
              log = log + 'Success: ' + name + ' registered successfully!\n';
              savedBusiness++;
            } else if (response.data.Message === "Business already Registered against this Name") {
              log = log + 'Failed: Business already Registered against this Name: ' + name + ' \n';
              unsavedBusiness++;
            } else {
              log = log + 'Failed: ' + name + ' not registered!\n';
              unsavedBusiness++;
            }
            // console.log('savedbusinessCount', savedBusiness);
            // console.log('unsavedbusinessCount', unsavedBusiness);
          })
          .catch(() => {
            log = log + 'Failed: ' + name + ' not registered!\n';
            unsavedBusiness++;
            // console.log("Something went wrong!");
          });
      }
    }));
    log = log + 'Businesses Registered: ' + savedBusiness + '\n';
    log = log + 'Businesses Not Registered: ' + unsavedBusiness + '\n';
    log = log + '\n';
    // console.log(log);
    var logJson = {
      'log': log
    };
    axios.post('business/csvLogs',logJson).then((response)=> {
      // console.log(response);
    })
    handleLoader(false);
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }, 1000);
  };

  const changeHandler = (event) => {
    // console.log(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete(results) {
        console.log(results.data);
        setBusinesses(results.data);
      },
    });
  };
  return (
    <>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MDButton color="dark" width="30%" onClick={handleShow} style={{marginRight:'8px'}}>
          Import Business
        </MDButton>
      </div>

      <Modal size="lg" show={show} onHide={handleClose} style={{ zIndex: "1200" }}>
        <Modal.Header closeButton>
          <Modal.Title>Import Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Choose CSV File</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".xlsx, .xls, .csv"
                    placeholder="Add CSV file"
                    onChange={changeHandler}
                  />
                  <Form.Text style={{ color: "red" }}>* File should be in .csv format.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Container>
          {loader ? (
            <div className="w-100 text-center mb-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveBusiness}>
            Import Business
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default businessImportCSV;
