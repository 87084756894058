
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import BusinessUpdateModal from "layouts/modals/businessUpdateModal";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import BCard from 'react-bootstrap/Card';
import axios from "../../utils/axios";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CiImageOff } from "react-icons/ci";
import MDButton from "components/MDButton";
import AdvertisementModal from "layouts/modals/AdvertisementModal";
import { color } from "@mui/system";
import imageUrl from "utils/imageUrl";
import Ad from "./sections/Ad";
import Spinner from "react-bootstrap/Spinner";

function BusinessDetailData() {
    const [status, setStatus] = useState(false);
    const [feeDisable, setFeeDisable] = useState(true);
    const [advertisement, setAdvertisement] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [adId, setAdId] = useState('');
    // const [advertisements, setAdvertisements] = useState([]);
    // const { state } = useLocation();
    const [business, setBusiness] = useState(null);
    const {id} = useParams();
    const [fee, setFee] = useState(0);
    const [payment, setPayment] = useState(0);
    // const updateHandler = (data) => {
    //     setBusiness(data);
    // }

    // const getBusinessViewFees = async () => {
    //     console.log('h`ello');
    //     if(business !==null){
    //     console.log('hello23');
    //         await axios.get('business/getBusinessViewFees/' + business._id).then((response) => {
    //             let views = response.data.Data;
    //             let payment = 0;
    //             views.map((view) => {
    //                 payment = payment + view.BusinessViewFees;
    //                 setPayment(payment);
    //             });
    //             console.log('payment', payment);
    //         }).catch((error) => console.log(error));
    //     }
    // }

    const fetchBusinessData = async () => {
        setIsLoaded(true);
            axios.get(`business/GetbusinessById/${id}`).then( async(res)=> {
                setBusiness(res.data.Data);
                setFee(res.data.Data.ViewFees);
                console.log(res);
                // setFee(business.ViewFees);
                // await getBusinessViewFees();
        setIsLoaded(false);
                return axios.get('business/getBusinessViewFees/'+res.data.Data._id);
            }).then((response) => {
                let views = response.data.Data;
                let payment = 0;
                views.map((view) => {
                    payment = payment + view.BusinessViewFees;
                    setPayment(payment);
                });
            }).catch((error) => {
                console.log('Error in loading the data!', error);
            });
    }

    useEffect(() => {
        fetchBusinessData();
    },[]);

    const handleEdit = () => {
        setFeeDisable(!feeDisable);
    }

    const handleFeeUpdate = async () => {
        // console.log(fee);
        const json = {
            'newFees': fee
        };
        await axios.put('business/updateBusinessViewFees/' + business._id, json).then((response) => {
            // console.log(response.data);
        });
        setFeeDisable(true);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container>
                {
                    isLoaded ? <div className="w-100 text-center mb-4">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                    : business && <BCard>
                    <BCard.Body>
                        {/* <div style={{ marginLeft: '20px' }}> */}
                        <Row>
                            <Col md='6'>
                                <Row>
                                    <div style={{ margin: '20px' }}>
                                        <p><b>Business Name:</b></p>
                                        <b style={{ fontSize: '1.8rem' }}>{business.Name}</b>
                                    </div>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <div style={{ margin: '20px' }}>
                                        <small style={{ paddingRight: '50px' }}><b>Sponsor Code Redemptions:</b></small>
                                        <b style={{
                                            fontSize: '1.4rem',
                                            color: "green",
                                            borderStyle: 'solid',
                                            paddingLeft: '15px',
                                            paddingRight: '15px',
                                            paddingBottom: '8px',
                                            paddingTop: '8px',
                                            borderRadius: '5px',
                                            borderColor: "black"
                                        }}>{business.CodeRedemptions}</b>
                                    </div>
                                </Row>
                            </Col>
                            <Col md='6'>
                                <div className="text-center">
                                    {(business.businessImage !== "") ?
                                        business.Image.includes('blob') ?
                                            <img src={`${imageUrl}${business.Image}`} className="w-50" style={{
                                                height: '200px',
                                                objectFit: 'cover'
                                            }} />
                                            :
                                            <img src={`${imageUrl}${business.Image}`} className="w-50" style={{
                                                height: '200px',
                                                objectFit: 'cover',
                                                marginBottom: '20px'
                                            }} />
                                        :
                                        <CiImageOff style={{
                                            fontSize: '10rem',
                                            marginTop: "30px",
                                            marginLeft: "10px"
                                        }} />
                                    }
                                    {/* <MDButton className='btn btn-primary' color='dark'>Edit Logo</MDButton> */}
                                    {/* <MDButton className='btn btn-primary' color='dark'> */}
                                    <BusinessUpdateModal business={business} />
                                    {/* </MDButton> */}
                                    {/* <MDButton style={{marginLeft:'10px'}} className='btn btn-primary' color='dark' onClick={event => handleCLick(event, business._id)}>Delete</MDButton> */}

                                    {/* <div class="dropdown"> */}
                                    {/* <MDButton class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" color="info">Options</MDButton>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <BusinessUpdateModal business={business} />
                                            <li><a class="dropdown-item" href="#" onClick={event => handleCLick(event, business._id)}>Delete</a></li>
                                        </ul> */}
                                    {/* </div> */}
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        
                        <div style={{ margin: '20px', marginTop: '30px', marginBottom: '30px' }}>
                            <Row>
                                <Col md='3'>
                                    <small><b>Fees per View ($):</b></small>
                                </Col>
                                <Col md='5'>
                                    <input className="form-control" type="number" min='1' value={fee} disabled={feeDisable} onChange={(event) => setFee(event.target.value)} />
                                </Col>
                                <Col md='2'>
                                    {feeDisable ? (
                                    <Button className="w-100" onClick={handleEdit}>Edit</Button>
                                    ) : (
                                    <Button className="w-100" onClick={handleEdit} style={{backgroundColor:'grey'}}>Cancel</Button>
                                    )}
                                </Col>
                                {feeDisable ? (
                                    <div></div>
                                ) : (
                                    <Col md='2'>
                                        <Button className="w-100" onClick={handleFeeUpdate}>Update</Button>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        <hr></hr>
                        <Row>
                            <Col md='6'>
                                <div className="text-center" style={{ margin: '20px' }}>
                                    <small><b>Business Views:</b></small>
                                    <div className="text-center" style={{ margin: '20px' }}>
                                        <div className="counters mx-auto" style={{width: 'fit-content'}}>
                                        <b >{business.Views}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md='6'>
                                <div className="text-center" style={{ margin: '20px' }}>
                                    <small><b>Outstanding Payment:</b></small>
                                    <div className="text-center" style={{ margin: '20px' }}>
                                    <div className="counters mx-auto" style={{width: 'fit-content', color: 'red'}}>
                                        <b >${payment}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md='6'>
                                <div className="text-center" style={{ margin: '20px' }}>
                                    <small><b>Website Views:</b></small>
                                    <div className="text-center" style={{ margin: '20px' }}>
                                     <div className="counters mx-auto" style={{width: 'fit-content'}}>
                                        <b>{business.WebsiteViews}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md='6'>
                                <div className="text-center" style={{ margin: '20px' }}>
                                    <small><b>Business Calls:</b></small>
                                    <div className="text-center" style={{ margin: '20px' }}>
                                        <div className="counters mx-auto" style={{width: 'fit-content'}}>
                                        <b >{business.Calls}</b>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr></hr>
                        <div style={{ marginLeft: '20px' }}>
                            <Row>
                                <p className="p" style={{ fontSize: '1.4rem' }}>
                                    <b><u>Business Address</u></b>
                                </p>
                            </Row>
                            <Row>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>Street Address:</b> <br></br>{business.Street}
                                    </p>
                                </Col>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>City:</b> <br></br>{business.City}
                                    </p>
                                </Col>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>State:</b> <br></br>{business.State}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>ZipCode:</b> <br></br>{business.Zipcode}
                                    </p>
                                </Col>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>Country:</b> <br></br>{business.Country}
                                    </p>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <p className="p" style={{ fontSize: '1.4rem' }}>
                                    <b><u>Contact Info</u></b>
                                </p>
                            </Row>
                            <Row>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>Email:</b><br></br> {business.Email}
                                    </p>
                                </Col>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>Website:</b> <br></br>{business.Website}
                                    </p>
                                </Col>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>Phone:</b> <br></br>{business.Phone}
                                    </p>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md='4'>
                                    <p className="p" style={{ fontSize: '1.4rem' }}>
                                        <b><u>Categories</u></b>
                                    </p>
                                </Col>

                                <Col md='8'>
                                    <p className="p" style={{ fontSize: '1rem' }}>
                                        <b>Your Categories:</b> <br></br>
                                        {
                                            business.Categories !== undefined && business.Categories.length != 0 ?
                                                business.Categories.toString().split(",").map((category, index) => {
                                                    return <span key={index} className="badge p-2 bg-danger text-capitalize me-2 mt-2">{category}</span>
                                                })
                                                :
                                                <span className="badge p-2 bg-danger text-capitalize me-2 mt-2">No Categories</span>
                                        }
                                    </p>
                                </Col>
                            </Row>
                            <hr></hr>
                            <Ad advertisementCode={business.advertisementCode}/>
                        </div>

                    </BCard.Body>
                </BCard>
                }
            </Container>
            <Footer />
        </DashboardLayout>
    );
}

export default BusinessDetailData;