

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";

import { useEffect, useState } from "react";
import axios from "../../../utils/axios";
import { Link, useNavigate } from "react-router-dom";


export default function data(products) {
  const navigate = useNavigate();
  const [productUIElements, setProductUIElements] = useState([]);
  // console.log("generic products",genericProducts);
  useEffect(() => {
    const FetchData = () => {
      if (products !== undefined && products.length > 0) {
        const dataTemp = products.map((product) => ({
          name: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {product.productName}
            </MDTypography>
          ),
          productId: (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="dark"
              fontWeight="medium"
            >
              {product._id}
            </MDTypography>
          ),
          status: (
            <MDBox ml={-1}>
              <MDButton onClick={() => {
                navigate('/property/floor/room/product', {
                  state: product
                });
              }}>View</MDButton>
            </MDBox>
          ),
        }));
        setProductUIElements(dataTemp);
      }
    };
    FetchData();
  }, []);

  return {
    columns: [
      { Header: "product name", accessor: "name", width: "15%", align: "left" },
      { Header: "product Id", accessor: "productId", width: "65%", align: "center" },
      { Header: "status", accessor: "status", width: "20%", align: "center" },
    ],
    rows: productUIElements,
  };
}