import React, { useState } from "react";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Spinner from "react-bootstrap/Spinner";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import historyTableData from "./data/historyTableData";

function HistoryTable() {
  const [loader, setLoader] = useState(true);
  const handleLoader = (val) => {
    setLoader(val);
  };
  const [date, setDate] = useState(new Date());
  const { columns, rows } = historyTableData(handleLoader, date);
  const formatDate = (value) => {
    let day = value.getDate();
    let month = value.getMonth() + 1;
    const year = value.getFullYear();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }
    const finalDate = `${year}-${month}-${day}`;
    return finalDate;
  };
  const maxDate = formatDate(new Date());
  const inputDate = formatDate(date);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <MDTypography variant="h6" color="white">
                    History
                  </MDTypography>
                  <div className="d-flex justify-end">
                    <input
                      type="date"
                      min="2022-01-01"
                      max={maxDate}
                      className="form-control"
                      onChange={(event) => setDate(new Date(event.target.value))}
                      value={inputDate}
                    />
                  </div>
                </div>
              </MDBox>
              <MDBox pt={3}>
                {rows.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    {loader ? (
                      <div className="w-100 text-center mb-4">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="text-center mb-4">History not found!</div>
                    )}
                  </>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default HistoryTable;
