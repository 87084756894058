
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import BCard from 'react-bootstrap/Card';
import axios from "../../utils/axios";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { CiImageOff } from "react-icons/ci";
import imageUrl from "utils/imageUrl";
// import SliderCarousel from "../slider/carousel";

function ServiceData(props) {
  const { state } = useLocation();
  // console.log(axios.defaults.baseURL);
  // console.log(state.image)
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
        <BCard>
          <BCard.Body>
            <Row>
              <Col md="4"
                className="d-flex align-items-center">
                <div className="text-center">
                  {(state.image !== '') ?
                    (< img src={`${imageUrl}${state.image}`} className="w-100" style={{
                      height: '300px',
                      objectFit: 'cover'
                    }} />)
                    :
                    <CiImageOff style={{
                      fontSize: '10rem',
                      marginTop: "50px",
                      marginLeft: "10px"
                    }} />
                  }
                </div>
              </Col>
              <Col md="8">
                <h6>Notes:</h6><b style={{ fontSize: '1rem' }}>{state.serviceNotes}</b>
                <hr />
                <Row>
                  <Row>
                    {/* <Col md="6">
                      <p className="p" style={{fontSize:'1rem'}}>
                        <b> Property Id:</b><br></br> {state.propertyId}
                      </p>
                    </Col> */}
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Service Company ID:</b> <br></br>{state.serviceCompanyId}
                      </p>
                    </Col>
                    <Col md="6">
                      <p className="p" style={{ fontSize: '1rem' }}>
                        <b>Service Date:</b> <br></br>{state.serviceDate}
                      </p>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </BCard.Body>
        </BCard>
      </Container>
      <Footer />
    </DashboardLayout>
  );
}

export default ServiceData;